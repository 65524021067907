import React, { ReactNode } from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import "./data.css";
import { BIDnaviBunner } from "StaticPages/Components/Commons/BIDnaviBunner";
import { Box, css } from "@mui/system";
import { Grid, Hidden, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SectionTitle2 } from "StaticPages/Components/Commons/SectionTitle2";
import { SectionText1 } from "StaticPages/Components/Commons/SectionText1";
import { Triangle } from "StaticPages/Components/Commons/Triangle";
import { FadeAndSlideScrollTriggerAnimation } from "StaticPages/Components/FadeAndSlideScriollTriggerAnimation";
import { PageSummary } from "StaticPages/Components/Commons/PageSummary";
import { Spacer } from "mui-extensions/src/Components/Spacer";
import { ServiceImageCollectionItem } from "StaticPages/Components/Commons/ServiceImageCollectionItem";
import { ScrollTrigger } from "mui-extensions/src/ScrollTrigger";
import { SkewAnimation } from "StaticPages/Components/Animations/SkewAnimation";
import { FadeAndSlideAnimation } from "StaticPages/Components/Animations/FadeAndSlideAnimation";
import { TransitionImage } from "StaticPages/Components/TransitionImage";

export default () => {
    const { t } = useTranslation();
    return <>
        <Helmet title="BIDnavi DATA | VISUALIZ INC" />
        <Layout>
            <div>
                <Main />
            </div>
        </Layout>
    </>;
};


const Main = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return <>
        <main className="Data">
            <PageSummary
                title={<>
                    {t("全く新しい")}<Hidden smUp><br /></Hidden>
                    {t("マーケティングを貴社に")}
                </>}
                subTitle={t("BIDnavi DATA")}
                src={"/images/DATA_top.jpg"}
                description={<>
                    <Typography variant="h6" component="p">{t("“BIDnavi DATA”とは")}</Typography>
                    {t("⾃社サイトが⼀瞬で情報ポータルに")}<Hidden smUp><br /></Hidden>
                    {t("⽣まれ変わるサービス")}<br />
                    {t("毎日補助金とプロポーザル情報を")}  <Hidden smUp><br /></Hidden>
                    {t("お客様サイトに掲載します")}<br />
                </>}
            />

            <div className="menuselector">
                <ul>
                    <li><Link to="#Anc1">BIDnavi DATAとは</Link></li>
                    <li><Link to="#Anc2">情報の中身</Link></li>
                    <li><Link to="#Anc3">料金プラン</Link></li>
                    <li><Link to="#Anc4">導入について</Link></li>
                </ul>
            </div>

            <Box
                mt={{ xs: 8, sm: 10, md: 12 }}
                px={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("“BIDnavi DATA”導⼊でサイトは変わる")}
                />
                <SectionText1>
                    {t("Webサイトには情報の更新が不可⽋です！そんなこと⾔われても…")}<br />
                    {t("今や企業や個⼈に関わらず、事業を⾏う上で不可⽋になったwebサイトでの情報発信。")}<br />
                    {t("しかし結果を出す前にseoを始めとするweb独⾃の技術、内容の精査など管理者にとって様々なハードルが待ち構えています")}<br />
                    {t("固定化を防ぎリピータや新規訪問者を増やすための内容の更新は管理コストを引き上げ、管理者の業務を圧迫します。")}
                </SectionText1>

                <SectionText1>
                    {t("私たちがお届けする全く新しいサービス”BIDnavi DATA”は現在のビジネスに不可⽋な情報を必要なだけ、貴社のホームページに表⽰するサービ スです。また表⽰するだけでなく検索システムまでを提供しますので、訪問者にはあたかも貴社のサイトでデータ収集と検索サービスを⾏ なっているように振る舞います。")}
                </SectionText1>
            </Box>

            <ScrollTrigger once>
                {state =>
                    <section className="TBBgWhiteM DataStep">
                        <FadeAndSlideAnimation
                            in={state === "entered"}
                            delay={0}
                            transform={{
                                translate: { y: "60px", x: "0" },
                                rotate: "0deg",
                                scale: 0.9
                            }}
                        >
                            <div>
                                <p>情報が更新されない</p>
                                <div>
                                    <img src="/images/DataSite01.png" alt="情報更新されない" />
                                </div>
                            </div>
                        </FadeAndSlideAnimation>
                        <p className="triangle-right"><img src="/images/DataArrow.png" alt="→" /></p>
                        <FadeAndSlideAnimation
                            in={state === "entered"}
                            delay={200}
                            transform={{
                                translate: { y: "60px", x: "0" },
                                rotate: "0deg",
                                scale: 0.9
                            }}
                        >
                            <div>
                                <p>情報を毎⽇⾃動更新</p>
                                <div>
                                    <img src="/images/DataSite02.png" alt="情報を毎日自動更新" />
                                </div>
                            </div>
                        </FadeAndSlideAnimation>
                        <p className="triangle-right"><img src="/images/DataArrow.png" alt="→" /></p>
                        <FadeAndSlideAnimation
                            in={state === "entered"}
                            delay={400}
                            transform={{
                                translate: { y: "60px", x: "0" },
                                rotate: "0deg",
                                scale: 0.9
                            }}
                        >
                            <div>
                                <p>リピーターの確保</p>
                                <div>
                                    <img src="/images/DataSite03.png" alt="リピーター確保" />
                                </div>
                            </div>
                        </FadeAndSlideAnimation>
                    </section>
                }
            </ScrollTrigger>

            <Box
                mt={{ xs: 8, sm: 10, md: 12 }}
                px={{ xs: 3, sm: 4, md: 6 }}
                py={8}
                bgcolor={theme.palette.background.default}
                mx="auto"
                maxWidth="1080px"
                textAlign="center"
            >
                <FadeAndSlideScrollTriggerAnimation>
                    <Box>
                        <Typography variant="h6" >
                            {t("導⼊から毎⽇のデータ更新までTechバリアフリーでお届けします")}
                        </Typography>
                    </Box>
                    <Box mt={3}>
                        <Typography variant="body1">
                            {t("導⼊から情報の表⽰まで最短で30分。")}<br />
                            {t("その後は弊社クラウドよりサービスが展開されるためメンテナンスフリーです。")}<br />
                            {t("別途導⼊サポートをお申し込みいただく事で、技術的な部分もtechバリアフリーが可能となります。")}
                        </Typography>
                    </Box>
                </FadeAndSlideScrollTriggerAnimation>
            </Box>

            <Box
                mt={{ xs: 8, sm: 10, md: 12 }}
                px={{ xs: 1, sm: 2, md: 4 }}
                mx="auto"
                maxWidth="1080px"
                textAlign="center"
            >
                <SectionTitle2
                    title={t("どのような情報があるの？")}
                />
                <Box mx="auto" pb={3} pt={1}>
                    <SectionText1 css={css({ textAlign: "center" })}>
                        {t("国や⾃治体の6,000を超えるサイト、")}<br />
                        {t("数百万ページから必要な情報のみを抜粋、")}<br />
                        {t("⾃動分類された情報が毎⽇提供されます。")}
                    </SectionText1>
                </Box>

                <Box px={{ xs: 1, md: 8 }}>
                    <Grid container>
                        <Grid item xs={12} sm={4}>
                            <Box p={1} sx={{ height: { xs: "120px", sm: "auto" } }}>
                                <ServiceImageCollectionItem
                                    src="/images/DATA_info_bid.jpg"
                                    title={t("入札\nプロポーザル情報")}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box p={1} sx={{ height: { xs: "120px", sm: "auto" } }}>
                                <ServiceImageCollectionItem
                                    src="/images/DATA_info_subsidy.jpg"
                                    title={t("補助金・助成金\n交付金情報")}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box p={1} sx={{ height: { xs: "120px", sm: "auto" } }}>
                                <ServiceImageCollectionItem
                                    src="/images/DATA_info_New-corporation.jpg"
                                    title={t("毎日の\n新設法人リスト")}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box p={1} sx={{ height: { xs: "120px", sm: "auto" } }}>
                                <ServiceImageCollectionItem
                                    src="/images/DATA_search.jpg"
                                    title={t("検索システム")}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    mt={{ xs: 2, sm: 4, md: 6 }}
                    px={{ xs: 2, sm: 3, md: 4 }}
                >
                    <SectionText1>
                        {t("入札・プロポーザル情報は48種類に分類され、補助⾦・助成⾦・交付⾦は3パート19種類に分類されています。")}<br />
                        {t("正答率は95%程度（弊社調査）となっており、AI及びRPAを使った⽇本語データ分類ではトップクラスの精度を備えております。")}<br />
                        {t("現在BIDnaviでは国や⾃治体から財団、特殊法⼈など6,000を超える組織から情報を取得・分類された情報を提供しています。")}<br />
                        {t("これら毎⽇更新される情報をBIDnaviだけでなくAPIを使い、様々なサイトに使⽤していただくサービスがBIDnavi DATAです。")}<br />
                        <small> {t("100%全ての情報取得を保証する物ではございません。また掲載された内容に関しての保証は出来ません。予めご了承ください。")}</small>
                    </SectionText1>
                    <SectionText1>
                        {t("刻々と変化するビジネスに対応するには、情報のタイムラグを出来るだけ無くす事が重要です。 またそれらの情報を収集し選択する仕組みが必要な事は⾔うまでもありません。 通常これらの仕組みを⾃社サイトでの運⽤するには多⼤なコストが必要です。")}<br />
                        {t("●例えば⼠業の⽅が運営している⾃社サイトに数千の省庁や⾃治体、様々な財団や特殊法⼈からの補助⾦・助成⾦情報が表⽰されたらいかがでしょうか。")}<br />
                        {t("●例えばクリエイターの⽅々が登録しているサイトに⾃治体から発信されるWebサイトやパンフレットデザインのプロポーザル情報が掲載されていたら如何でしょうか。")}<br />
                        {t("サイトの訪問者にとっては、⾃分にどれだけ有益な情報があるか、それは貴重な時間を使ってサイトを訪れる訪問者にとって⾮常に重要な要素です。")}
                    </SectionText1>
                </Box>
            </Box>

            <Box
                mt={{ xs: 3, sm: 4, md: 6 }}
                px={{ xs: 1, sm: 2, md: 3 }}
                mx="auto"
                maxWidth="1080px"
                textAlign="center"
            >
                <Box px={{ xs: 1, sm: 8 }}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <Box p={1}>
                                <ServiceImageCollectionItem
                                    src="/images/DATA_info_Professional.jpg"
                                    title={t("⼠業サイトに全省庁の補助⾦・助成⾦情報を")}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box p={1}>
                                <ServiceImageCollectionItem
                                    src="/images/DATA_info_creator.jpg"
                                    title={t("クリエイターを顧客に持つサイトにデザイン・制作に関するプロポーザル情報を")}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    mt={{ xs: 2, sm: 3, md: 5 }}
                    px={{ xs: 3 }}
                >
                    <SectionText1 css={css({ textAlign: "center" })}>
                        {t("毎⽇数千を超えるサイトから発表される情報を取得するだけでなく検索可能なデータに分類する事で情報は価値を増します。")}<br />
                        {t("このように価値のある情報を低コストであるだけでなく、御社サイトにコードを埋め込むだけで今⽇から表⽰が可能になります。")}<br />
                        {t("私たちは当⾯このサービスを当⾯の間、3,000サイトのお客様を⽬処に提供する予定です。")}
                    </SectionText1>
                </Box>

                <FadeAndSlideScrollTriggerAnimation>
                    <Box className="sample-wrap" mt={6}>
                        <Link to="/sample">
                            <img src="/images/DATA_sample.jpg" alt="サンプルデータ" />
                            <p>サンプルデータへ</p>
                        </Link>
                    </Box>
                </FadeAndSlideScrollTriggerAnimation>
            </Box>

            <section className="TBBgGrayM">
                <h4 className="NonBorderH">BIDnavi DATAサービス利⽤料⾦事例</h4>
                <table>
                    <tbody>
                        <tr>
                            <th>プラン</th>
                            <td>金額</td>

                            <td>情報範囲</td>
                            <td>検索業種</td>
                        </tr>
                        <tr>
                            <th>プレミアム</th>
                            <td>29,800円/⽉</td>
                            <td>全国</td>
                            <td>48種</td>
                        </tr>
                        <tr>
                            <th>スタンダード</th>
                            <td>7,800円/月</td>
                            <td>全国</td>
                            <td>12種</td>
                        </tr>
                    </tbody>
                </table>

                <div className="icon-plus"></div>
                <div className="covid-pic">
                    <img src="/images/DATA_info_bidnavi.jpg" alt="" />
                    <p>給付⾦・助成⾦・補助⾦情報を無料でお届け</p>
                </div>
                <div className="icon-plus"></div>
                <div className="covid-pic">
                    <img src="/images/DATA_info_covid19.jpg" alt="" />
                    <p>新型コロナ関連給付⾦・助成⾦・補助⾦情報を無料でお届け</p>
                </div>
            </section>

            <Box
                maxWidth="720px"
                mx="auto"
                py={{ xs: 4, sm: 6, md: 8 }}
                px={{ xs: 3, sm: 4, md: 6 }}
                id="Anc4">
                <ScrollTrigger once>
                    {state =>
                        <>
                            <SkewAnimation in={state === "entered"}>
                                <Box mx="auto" pb={3}>
                                    <Typography variant="h6" component="h2">{t("このサービスをお勧めする⽅")}</Typography>
                                </Box>
                            </SkewAnimation>
                            <Grid container sx={{ height: "100%" }}>
                                <Grid item xs={12} sm={4} sx={{ height: "100%" }}>
                                    <FadeAndSlideAnimation in={state === "entered"}>
                                        <Box p={1} my="auto" sx={{ height: "100%" }}>
                                            <Box css={blue}>
                                                <Spacer />
                                                <Typography>{t("サイトの価値向上を行いたい方")}</Typography>
                                                <Spacer />
                                            </Box>
                                        </Box>
                                    </FadeAndSlideAnimation>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ height: "100%" }}>
                                    <FadeAndSlideAnimation in={state === "entered"}>
                                        <Box p={1} my="auto" sx={{ height: "100%" }}>
                                            <Box css={blue}>
                                                <Spacer />
                                                <Typography>{t("低コストでのサービスをお探しの方")}</Typography>
                                                <Spacer />
                                            </Box>
                                        </Box>
                                    </FadeAndSlideAnimation>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ height: "100%" }}>
                                    <FadeAndSlideAnimation in={state === "entered"}>
                                        <Box p={1} my="auto" sx={{ height: "100%" }}>
                                            <Box css={blue}>
                                                <Spacer />
                                                <Typography>{t("顧客を増やすためにサイトへのリピータを増やしたい方")}</Typography>
                                                <Spacer />
                                            </Box>
                                        </Box>
                                    </FadeAndSlideAnimation>
                                </Grid>
                            </Grid>
                        </>
                    }
                </ScrollTrigger>
            </Box>

            <Box position="relative" overflow="hidden" p={3}>
                <TransitionImage
                    src="/images/DATA_recommend.jpg"
                    alt=""
                    css={css({
                        objectFit: "cover",
                        position: "absolute",
                        margin: "auto",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -1,
                    })}
                />
                <Box
                    sx={{
                        maxWidth: "720px",
                        width: "100%",
                    }}
                    mx="auto"
                >
                    <ScrollTrigger once>
                        {state =>
                            <Grid container >
                                <Grid item xs={12} sm={6} >
                                    <FadeAndSlideAnimation in={state === "entered"}>
                                        <Box p={1} my="auto">
                                            <Box css={blue}>
                                                <Spacer />
                                                <Typography>{t("お客様に有益な情報提供サービスを自社サイトで運営可能です。")}</Typography>
                                                <Spacer />
                                            </Box>
                                        </Box>
                                    </FadeAndSlideAnimation>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <FadeAndSlideAnimation in={state === "entered"}>
                                        <Box p={1} my="auto" >
                                            <Box css={blue}>
                                                <Spacer />
                                                <Typography>{t("表示したい場所にソースをコピペするだけ。")}</Typography>
                                                <Spacer />
                                            </Box>
                                        </Box>
                                    </FadeAndSlideAnimation>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <FadeAndSlideAnimation in={state === "entered"}>
                                        <Box p={1} my="auto">
                                            <Box css={blue}>
                                                <Spacer />
                                                <Typography>{t("検索システムごと提供されます。")}</Typography>
                                                <Spacer />
                                            </Box>
                                        </Box>
                                    </FadeAndSlideAnimation>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <FadeAndSlideAnimation in={state === "entered"}>
                                        <Box p={1} my="auto">
                                            <Box css={blue}>
                                                <Spacer />
                                                <Typography>{t("コストは一日たったの200円〜")}</Typography>
                                                <Spacer />
                                            </Box>
                                        </Box>
                                    </FadeAndSlideAnimation>
                                </Grid>
                            </Grid>
                        }
                    </ScrollTrigger>
                </Box>
            </Box>

            <Box
                sx={{
                    background: theme.palette.background.default
                }}
                p={{
                    xs: 3,
                    sm: 4,
                    md: 5
                }}>
                <SectionTitle2
                    title={<>
                        {t("どのような価値を")}<Hidden smUp ><br /></Hidden>
                        {t("もたらすか")}
                    </>}
                />

                <Box
                    sx={{
                        maxWidth: "720px"
                    }}
                    my={{
                        xs: 3,
                        sm: 4,
                        md: 5
                    }}
                    mx="auto"
                >
                    <SectionText1>
                        {t("現在企業はSNSや⾃社サイトをオウンドメディア化し、顧客の囲い込みを⾏う事例が増えています。")}<br />
                        {t("しかし顧客に対し宣伝⾊が強くなると、その顧客はメディアから離れていく傾向があります。")}<br />
                        {t("宣伝⾊を極⼒減らしながら有益な情報を発信することが最近のオウンドメディア運営の基本となっていますが、このような理由から多くの運営者がSNSなどから⾃社サイトへの引き込みに苦慮しています。")}<br />
                        {t("また顧客が必要な情報を毎⽇公開するだけの労⼒を割くなど、⾮常に厳しい事は⾔うまでもありません。")}<br />
                        {t("私たちが提供する”BIDnavi DATA”の運⽤に労⼒も専⾨知識も必要ありません。")}<br />
                        {t("お申し込みいただき、発⾏されたコードをサイトの置きたい場所にペーストするだけです。")}<br />
                        {t("後は毎⽇必要な情報が貴社サイトに表⽰されます。")}<br />
                    </SectionText1>
                </Box>

                <Box>
                    <Box my={{
                        xs: 3,
                        sm: 4,
                        md: 5
                    }}>
                        <Typography variant="h6" component="h4">
                            {t("BIDnavi DATAまでのステップは３つ")}
                        </Typography>
                    </Box>
                    <Box>
                        <StepItem text={t("申し込み")} />
                        <StepTriangle />
                        <StepItem text={t("情報の範囲決定")} />
                        <StepTriangle />
                        <StepItem text={t("コードをペースト")} />
                    </Box>
                </Box>
            </Box>

            <Box maxWidth="1080px"
                p={2}
                mt={{
                    xs: 2,
                    sm: 10,
                    md: 14
                }}
                mx="auto"
            >
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Box p={1}>
                            <Link
                                to="/q-a"
                                css={[
                                    a,
                                    css({
                                        background: theme.palette.secondary.main,
                                        color: theme.palette.secondary.contrastText
                                    })
                                ]}>
                                {t("Q & A")}
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box p={1}>
                            <Link
                                to="/contact"
                                css={[
                                    a,
                                    css({
                                        background: theme.palette.primary.main,
                                        color: theme.palette.primary.contrastText
                                    })
                                ]}
                            >
                                {t("お申し込み・お問い合わせ")}
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box>
                <BIDnaviBunner />
            </Box>
        </main >
    </>;
};

const StepTriangle = () => {
    return (
        <FadeAndSlideScrollTriggerAnimation
            transform={{
                scale: 0.8,
                translate: {
                    x: "0px",
                    y: "3rem"
                },
                rotate: "0deg"
            }}
        >
            <Triangle />
        </FadeAndSlideScrollTriggerAnimation>
    );
};


const StepItem = ({ text }: { text: ReactNode }) => {
    return (
        <FadeAndSlideScrollTriggerAnimation
            transform={{
                scale: 0.8,
                translate: {
                    x: "0px",
                    y: "3rem"
                },
                rotate: "0deg"
            }}
        >
            <Box css={stepItem}>
                <Typography textAlign="center">
                    {text}
                </Typography>
            </Box>
        </FadeAndSlideScrollTriggerAnimation>
    );
};

const stepItem = css`
    box-shadow: 7px 6px 10px 4px rgb(0 0 0 / 4%);
    max-width: 432px;
    margin: 24px auto;
    padding: 20px;
    background-color: #D5D5D5;
`;

const a = css`
    width: 100%;
    height: 4rem;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    opacity: 1;
    transition: all .3s;
    font-size: 1rem;
    border-radius: 8px;

    &:hover {
        opacity:.8;
        transition:all .3s;
    }
`;

const blue = css({
    color: "white",
    padding: "12px",
    background: "#14188d",
    borderRadius: "8px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "120px",
});